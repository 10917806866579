import React from 'react'
import styled from 'styled-components'

const ComingSoon = () => {



  return (
    <Wrapper>
      <a href="mailto:office@dotgis-pub.at" target="_blank"><span>Closed</span></a>
    </Wrapper>
  )
}

export default ComingSoon

const Wrapper = styled.div`
  bottom: 0px;
  margin: 1rem auto;
  left: 0px;
  right: 0px;

  a {
    display: block;
    width: 220px;
    height: 70px;
    z-index: 1000;
  }

  a:after {
    content: '';
    background: linear-gradient(120deg, #A10000, #FF6347, #000); /* Updated main color */
    background-size: 400% 400%;
    -webkit-clip-path: polygon(0% 100%, 4px 100%, 4px 4px, 216px 4px, 216px 66px, 4px 66px, 4px 100%, 100% 100%, 100% 0%, 0% 0%);
    -moz-animation: gradient 3s ease-in-out infinite, border 1s forwards ease-in-out reverse;
    -webkit-animation: gradient 3s ease-in-out infinite, border 1s forwards ease-in-out reverse;
    animation: gradient 3s ease-in-out infinite, border 1s forwards ease-in-out reverse;
  }

  a > span {
    display: block;
    background: linear-gradient(120deg, #A10000, #FF6347, #000); /* Updated main color */
    background-size: 400% 400%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-animation: gradient 3s ease-in-out infinite;
    -webkit-animation: gradient 3s ease-in-out infinite;
    animation: gradient 3s ease-in-out infinite;
  }

  /* helpers */
  .absolute-centering, body:after, a, a:after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }

  .text-formatting, a {
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
    letter-spacing: 2px;
    line-height: 70px;
    font-family: 'Montserrat';
    font-weight: 900;
    font-size: 38px;
  }

  /* motion */
  @-moz-keyframes gradient {
    0% {
      background-position: 14% 0%;
    }
    50% {
      background-position: 87% 100%;
    }
    100% {
      background-position: 14% 0%;
    }
  }
  @-webkit-keyframes gradient {
    0% {
      background-position: 14% 0%;
    }
    50% {
      background-position: 87% 100%;
    }
    100% {
      background-position: 14% 0%;
    }
  }
  @keyframes gradient {
    0% {
      background-position: 14% 0%;
    }
    50% {
      background-position: 87% 100%;
    }
    100% {
      background-position: 14% 0%;
    }
  }
  @-moz-keyframes border {
    0% {
      -webkit-clip-path: polygon(0% 100%, 4px 100%, 4px 4px, 216px 4px, 216px 66px, 4px 66px, 4px 100%, 100% 100%, 100% 0%, 0% 0%);
    }
    25% {
      -webkit-clip-path: polygon(0% 100%, 4px 100%, 4px 4px, 216px 4px, 216px 66px, 216px 66px, 216px 100%, 100% 100%, 100% 0%, 0% 0%);
    }
    50% {
      -webkit-clip-path: polygon(0% 100%, 4px 100%, 4px 4px, 216px 4px, 216px 4px, 216px 4px, 216px 4px, 216px 4px, 100% 0%, 0% 0%);
    }
    75% {
      -webkit-clip-path: polygon(0% 100%, 4px 100%, 4px 4px, 4px 4px, 4px 4px, 4px 4px, 4px 4px, 4px 4px, 4px 0%, 0% 0%);
    }
    100% {
      -webkit-clip-path: polygon(0% 100%, 4px 100%, 4px 100%, 4px 100%, 4px 100%, 4px 100%, 4px 100%, 4px 100%, 4px 100%, 0% 100%);
    }
  }
  @-webkit-keyframes border {
    0% {
      -webkit-clip-path: polygon(0% 100%, 4px 100%, 4px 4px, 216px 4px, 216px 66px, 4px 66px, 4px 100%, 100% 100%, 100% 0%, 0% 0%);
    }
    25% {
      -webkit-clip-path: polygon(0% 100%, 4px 100%, 4px 4px, 216px 4px, 216px 66px, 216px 66px, 216px 100%, 100% 100%, 100% 0%, 0% 0%);
    }
    50% {
      -webkit-clip-path: polygon(0% 100%, 4px 100%, 4px 4px, 216px 4px, 216px 4px, 216px 4px, 216px 4px, 216px 4px, 100% 0%, 0% 0%);
    }
    75% {
      -webkit-clip-path: polygon(0% 100%, 4px 100%, 4px 4px, 4px 4px, 4px 4px, 4px 4px, 4px 4px, 4px 4px, 4px 0%, 0% 0%);
    }
    100% {
      -webkit-clip-path: polygon(0% 100%, 4px 100%, 4px 100%, 4px 100%, 4px 100%, 4px 100%, 4px 100%, 4px 100%, 4px 100%, 0% 100%);
    }
  }
  @keyframes border {
    0% {
      -webkit-clip-path: polygon(0% 100%, 4px 100%, 4px 4px, 216px 4px, 216px 66px, 4px 66px, 4px 100%, 100% 100%, 100% 0%, 0% 0%);
    }
    25% {
      -webkit-clip-path: polygon(0% 100%, 4px 100%, 4px 4px, 216px 4px, 216px 66px, 216px 66px, 216px 100%, 100% 100%, 100% 0%, 0% 0%);
    }
    50% {
      -webkit-clip-path: polygon(0% 100%, 4px 100%, 4px 4px, 216px 4px, 216px 4px, 216px 4px, 216px 4px, 216px 4px, 100% 0%, 0% 0%);
    }
    75% {
      -webkit-clip-path: polygon(0% 100%, 4px 100%, 4px 4px, 4px 4px, 4px 4px, 4px 4px, 4px 4px, 4px 4px, 4px 0%, 0% 0%);
    }
    100% {
      -webkit-clip-path: polygon(0% 100%, 4px 100%, 4px 100%, 4px 100%, 4px 100%, 4px 100%, 4px 100%, 4px 100%, 4px 100%, 0% 100%);
    }
  }
`;

