import React from "react"
// import Countdown from '../components/Countdown'
import ComingSoon from '../components/ComingSoon'
import '../components/all.sass'
import logo from '../images/logo.png'
import dotgisPub from '../images/dotgis.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../utils/fontawesome'
import DynamicBrowserTabTitle from '../components/DynamicBrowserTabTitle'

export default function Home () {
  return (
    <>
    <DynamicBrowserTabTitle />
    <div className='container'>
      <div className='columns is-centered is-multiline'>
        <div className='column is-12 has-text-centered'>
          <img src={logo} style={{ width: '10rem', height: '10rem', padding: '2rem' }} />
          
          {/* <Countdown
        		timeTillDate="09 01 2022, 8:00 pm"
        		timeFormat="MM DD YYYY, h:mm a"
        	/> */}
  
        </div>
        <div className='column is-12 is-relative'>
          <ComingSoon />
        </div>
        <div className='column is-12 has-text-centered mt-2'>
          <h1 className='subtitle'>KAFFEE - PUB - BAR - LOUNGE</h1>
        </div>
        <div className='column is-12 has-text-centered'>
          <h2 className='subtitle'>Interessiert an einer Übernahme?</h2>
        </div>
        <div className='column is-12 has-text-centered'>
          Voll AUSGESTATTET uns sofort BETRIEBSBEREIT
        </div>
        <div className='column is-12 has-text-centered'>
        <a href='mailto:office@dotgis-pub.at'><FontAwesomeIcon className='has-text-primary' icon='envelope' style={{ fontSize: '2rem', marginBottom: '1rem' }} /></a>
        
        </div>
        <div className='column is-12 has-text-centered'>
            office@dotgis-pub.at
        </div>
        <div className='column is-12 has-text-centered'>
          <a href='tel:+4367689881566'><FontAwesomeIcon className='has-text-primary' icon='phone' style={{ fontSize: '2rem', marginBottom: '1rem' }} /></a>
        </div>
        <div className='column is-12 has-text-centered'>
          +43676 / 89881566
        </div>
        <div className='column is-12 has-text-centered'>
          oder
        </div>
        
        <div className='column is-12 has-text-centered'>
          <a href='tel:+4367689881942'><FontAwesomeIcon className='has-text-primary' icon='phone' style={{ fontSize: '2rem', marginBottom: '1rem' }} /></a>
        </div>
        <div className='column is-12 has-text-centered'>
          +43676 / 89881942
        </div>
      </div>
      <div className='column is-12 has-text-centered'>
        <img src={dotgisPub} alt='Dotgis Pub' />
      </div>
    </div>

    </>
  )
}
